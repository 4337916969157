import { watch } from 'vue';
import { ElMessageBox } from 'element-plus';

export default ({ form, dicts }) => {
    // 同比环比最小可选批次
    const batchMix = {
        t: { m: 202001, Q: "2020Q1" },
        h: { m: 201904, Q: "2019Q2" },
    };

    watch(
        () => form.ratio,
        (val) => {
            if (!form.minBatch && !form.maxBatch) return;
            const minMonth = dicts.batch.data.find((item) => item.id === form.minBatch)?.value;
            const maxMonth = dicts.batch.data.find((item) => item.id === form.maxBatch)?.value;
            // 同比
            if (val === '1' && (minMonth < batchMix.t.m || maxMonth < batchMix.t.m)) {
                ElMessageBox.confirm(`同比最小可选批次为“${batchMix.t.Q}”`, "提示", {
                    confirmButtonText: "知道啦",
                    showCancelButton: false
                }).then(() => {
                    const mixId = dicts.batch.data.find((item) => item.value === batchMix.t.m).id;// 可选最小id
                    if (minMonth < batchMix.t.m) form.minBatch = mixId;
                    if (maxMonth < batchMix.t.m) form.maxBatch = mixId;
                });
            }
            // 环比
            if (val === '2' && (minMonth < batchMix.h.m || maxMonth < batchMix.h.m)) {
                ElMessageBox.confirm(`环比最小可选批次为“${batchMix.h.Q}”`, "提示", {
                    confirmButtonText: "知道啦",
                    showCancelButton: false
                }).then(() => {
                    const mixId = dicts.batch.data.find((item) => item.value === batchMix.h.m).id;// 可选最小id
                    if (minMonth < batchMix.h.m) form.minBatch = mixId;
                    if (maxMonth < batchMix.h.m) form.maxBatch = mixId;
                });
            }
        }
    );

    // 判断当前批次是否禁用
    function isBatchDisabled(batch) {
        if (form.ratio === '1') {
            return batch < batchMix.t.m;
        }
        if (form.ratio === '2') {
            return batch < batchMix.h.m;
        }
        return false;
    }

    return { isBatchDisabled }
}