import { apiEgpmDictCode } from '@/request/egpmMaterialApi';
import { reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

export default ({ form }) => {
    const route = useRoute();

    const dicts = reactive({
        // 批次
        batch: {
            loading: false,
            data: []
        },
        // 省份
        province: {
            loading: false,
            data: []
        },
        // 城市
        city: {
            loading: false,
            data: [],
            searchStr: '',
            filter: (str) => {
                dicts.city.searchStr = str;
            }
        },
        // 餐饮类型
        cuisine1: {
            loading: false,
            data: []
        },
        // 菜系类型
        cuisine2: {
            loading: false,
            data: []
        },
        // 菜系
        cuisine3: {
            loading: false,
            data: [],
            searchStr: '',
            filter: (str) => {
                dicts.cuisine3.searchStr = str;
            }
        },
        // 原料大类
        material1: {
            loading: false,
            data: []
        },
        // 原料二类
        material2: {
            loading: false,
            data: []
        },
        // 原料细类
        material3: {
            loading: false,
            data: [],
            searchStr: '',
            filter: (str) => {
                dicts.material3.searchStr = str;
            }
        },
        // 风味大类
        flavor1: {
            loading: false,
            data: []
        },
        // 风味二级
        flavor2: {
            loading: false,
            data: []
        },
        // 制作工艺
        process1: {
            loading: false,
            data: []
        },
        // 奉客形式
        style1: {
            loading: false,
            data: []
        },
    })

    // 获取字典
    function getDict(params) {
        return new Promise((resolve, reject) => {
            params = { ...params, reportConfigId: route.query.reportId };
            apiEgpmDictCode(params).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    // 获取批次
    async function setBatch() {
        dicts.batch.loading = true;
        try {
            dicts.batch.data = await getDict({ code: 'batch_min' }) || [];
        } finally {
            dicts.batch.loading = false;
        }
    }

    // 省份
    async function setProvince() {
        dicts.province.loading = true;
        try {
            dicts.province.data = await getDict({ code: 'area1' }) || [];
        } finally {
            dicts.province.loading = false;
        }
    }

    // 城市
    async function setCity() {
        dicts.city.loading = true;
        try {
            const params = { code: 'area2' };
            if (form.province?.length) {
                params['p1'] = dicts.province.data.filter(item => form.province.includes(item.id)).map(item => item.dictValue);
            }
            dicts.city.data = await getDict(params) || [];
        } finally {
            dicts.city.loading = false;
        }
    }

    // 餐饮类型
    async function setCuisine1() {
        dicts.cuisine1.loading = true;
        try {
            dicts.cuisine1.data = await getDict({ code: 'cuisine1' }) || [];
        } finally {
            dicts.cuisine1.loading = false;
        }
    }

    // 菜系类型
    async function setCuisine2() {
        dicts.cuisine2.loading = true;
        try {
            const params = { code: 'cuisine2' };
            if (form.cuisine1?.length) {
                params['p1'] = dicts.cuisine1.data.filter(item => form.cuisine1.includes(item.id)).map(item => item.dictValue);
            }
            dicts.cuisine2.data = await getDict(params) || [];
        } finally {
            dicts.cuisine2.loading = false;
        }
    }

    // 菜系
    async function setCuisine3() {
        dicts.cuisine3.loading = true;
        try {
            const params = { code: 'cuisine3' };
            if (form.cuisine1?.length) {
                params['p1'] = dicts.cuisine1.data.filter(item => form.cuisine1.includes(item.id)).map(item => item.dictValue);
            }
            if (form.cuisine2?.length) {
                params['p2'] = dicts.cuisine2.data.filter(item => form.cuisine2.includes(item.id)).map(item => item.dictValue);
            }
            dicts.cuisine3.data = await getDict(params) || [];
        } finally {
            dicts.cuisine3.loading = false;
        }
    }

    // 原料大类
    async function setMaterial1() {
        dicts.material1.loading = true;
        try {
            dicts.material1.data = await getDict({ code: 'material1' }) || [];
        } finally {
            dicts.material1.loading = false;
        }
    }

    // 原料二类
    async function setMaterial2() {
        dicts.material2.loading = true;
        try {
            const params = { code: 'material2' };
            if (form.material1?.length) {
                params['p1'] = dicts.material1.data.filter(item => form.material1.includes(item.id)).map(item => item.dictValue);
            }
            dicts.material2.data = await getDict(params) || [];
        } finally {
            dicts.material2.loading = false;
        }
    }

    // 原料细类
    async function setMaterial3() {
        dicts.material3.loading = true;
        try {
            const params = { code: 'material3' };
            if (form.material1?.length) {
                params['p1'] = dicts.material1.data.filter(item => form.material1.includes(item.id)).map(item => item.dictValue);
            }
            if (form.material2?.length) {
                params['p2'] = dicts.material2.data.filter(item => form.material2.includes(item.id)).map(item => item.dictValue);
            }
            dicts.material3.data = await getDict(params) || [];
        } finally {
            dicts.material3.loading = false;
        }
    }

    // 风味大类
    async function setFlavor1() {
        dicts.flavor1.loading = true;
        try {
            dicts.flavor1.data = await getDict({ code: 'flavor1' }) || [];
        } finally {
            dicts.flavor1.loading = false;
        }
    }

    // 风味二级
    async function setFlavor2() {
        dicts.flavor2.loading = true;
        try {
            const params = { code: 'flavor2' };
            if (form.flavor1?.length) {
                params['p1'] = dicts.flavor1.data.filter(item => form.flavor1.includes(item.id)).map(item => item.dictValue);
            }
            dicts.flavor2.data = await getDict(params) || [];
        } finally {
            dicts.flavor2.loading = false;
        }
    }

    // 制作工艺
    async function setProcess1() {
        dicts.process1.loading = true;
        try {
            dicts.process1.data = await getDict({ code: 'process1' }) || [];
        } finally {
            dicts.process1.loading = false;
        }
    }

    // 奉客形式
    async function setStyle1() {
        dicts.style1.loading = true;
        try {
            dicts.style1.data = await getDict({ code: 'style1' }) || [];
        } finally {
            dicts.style1.loading = false;
        }
    }

    // 省份-切换
    watch(() => form.province, () => {
        setCity();
        form.city = [];
    })
    // 餐饮类型-切换
    watch(() => form.cuisine1, () => {
        setCuisine2();
        setCuisine3();
        form.cuisine2 = [];
        form.cuisine3 = [];
    })
    // 菜系类型-切换
    watch(() => form.cuisine2, () => {
        setCuisine3();
        form.cuisine3 = [];
    })
    // 原料大类-切换
    watch(() => form.material1, () => {
        setMaterial2();// 原料二类
        setMaterial3();// 原料细类
        form.material2 = [];
        form.material3 = [];
    })
    // 原料二类-切换
    watch(() => form.material2, () => {
        setMaterial3();// 原料细类
        form.material3 = [];
    })
    // 风味大类-切换
    watch(() => form.flavor1, () => {
        setFlavor2();
        form.flavor2 = [];
    })

    setBatch();// 批次
    setProvince();// 省份
    setCity();// 城市
    setCuisine1();// 餐饮类型
    setCuisine2();// 菜系类型
    setCuisine3();// 菜系
    setMaterial1();// 原料大类
    setMaterial2();// 原料二类
    setMaterial3();// 原料细类
    setFlavor1();// 风味大类
    setFlavor2();// 风味二级
    setProcess1();// 制作工艺
    setStyle1();// 奉客形式

    return { dicts }
}